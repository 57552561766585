import { createSlice } from "@reduxjs/toolkit";
import { fetchArtworksCollections } from "../../services";
import { artworkCollectionResponse } from "./types";

const initialState = {
	artworks: {
		config: {},
		pagination: {},
		data: [],
		currentPage: 0,
	},
	loading: "idle",
} as artworkCollectionResponse;

//artworkCollectionsSlice
export const artworkCollectionsSlice = createSlice({
	name: "artwork",
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder.addCase(fetchArtworksCollections.pending, (state) => {
			state.loading = "pending";
		});
		builder.addCase(fetchArtworksCollections.fulfilled, (state, action) => {
			state.loading = "succeeded";
			state.artworks = action.payload?.artworks;
		});
		builder.addCase(fetchArtworksCollections.rejected, (state, action) => {
			state.loading = "failed";
		});
	},
});
