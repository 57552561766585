import React from "react";

export default function Hero() {
	return (
		<section className='layout__hero'>
			<h1 className='layout__hero_title'>Browse Galleries</h1>
			<p className='layout__hero_description'>
				Disco has partnered with contemporary art galleries from around the
				world to bring you the most sought-after art on the market. Featuring
				artists that push the boundaries of contemporary art.
			</p>
			<div className='layout__mockButton'>Become a Member</div>
		</section>
	);
}
