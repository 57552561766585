import { AppLoader } from "../Application";
import Image from "../Ui/Image";
import { useGetArtworkQuery } from "../../features/artworks/artworkDetailsSlice";
import { Heart, ShoppingBag } from "@phosphor-icons/react";

export default function Details({ pageId }: { pageId: number }) {
	const { data, isLoading, isSuccess } = useGetArtworkQuery(pageId);
	const artwork = data?.data;

	console.log(artwork);

	return (
		<main className='details'>
			{isLoading && (
				<div className='details__loader'>
					<AppLoader />
				</div>
			)}

			{isSuccess && (
				<>
					<section className='details__image'>
						<Image src={artwork?.image_id as string} alt='' height={"100%"} />

						<div className='details__likes'>
							<Heart color='#fff' weight='fill' size={18} />
							{artwork?.id}
						</div>
					</section>
					<section className='details__content'>
						<section className='details__section black'>
							<h1 className='details__title white'>{artwork?.title}</h1>
							<p className='details__detail white'>
								by <span>{artwork?.artist_display}</span>
							</p>

							<div className='details__button'>
								Order Now{" "}
								<ShoppingBag color='#555' weight='duotone' size={20} />
							</div>
						</section>
						<section className='details__section'>
							<p className='details__heading'>Provenance</p>
							<p className='details__detail'>
								<small>{artwork?.provenance_text}</small>
							</p>
							{/*<!-- break here -->*/}
							<p
								className='details__heading'
								style={{
									marginTop: 30,
								}}
							>
								Tags
							</p>
							<p className='details__tags'>
								{artwork?.subject_titles?.map((subject, key) => (
									<div key={key} className='details__tag'>
										{subject}
									</div>
								))}
							</p>
						</section>
					</section>
				</>
			)}
		</main>
	);
}
