import { Collections } from "../../components/Artwork";
import Hero from "../../components/Ui/Hero";
import useArtworkCollections from "../../hooks/useArtworkCollections";

export default function ArtworksPage() {
	const data = useArtworkCollections();
	return (
		<>
			<Hero />
			<Collections data={data} handleClick={data.handleArtwork} />
		</>
	);
}
