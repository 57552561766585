import { useParams } from "react-router-dom";
import { DetailsPage } from "../../components/Artwork";

export default function ArtworkDetailsPage() {
	const { artworkId } = useParams();

	//should be wrapped in try/catch or updated by the current page state
	const id = parseInt(artworkId!) || 1;

	return (
		<>
			<DetailsPage pageId={id} />
		</>
	);
}
