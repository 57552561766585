import { ReactNode } from "react";
import Header from "../Ui/Header";
import { ScrollRestoration } from "react-router-dom";

interface AppLayoutProps {
	children: ReactNode;
}

export default function AppLayout({ children }: AppLayoutProps) {
	return (
		<main className='application__main'>
			<Header />

			{children}
			<ScrollRestoration
				getKey={(location, matches) => {
					return location.pathname;
				}}
			/>
		</main>
	);
}
