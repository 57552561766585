import { Link } from "react-router-dom";

import logo from "../../assets/images/logo.svg";

export default function Header() {
	return (
		<header className='header__container'>
			<Link to='/' preventScrollReset>
				{
					//@ts-ignore
					<img src={logo} alt='logo' width={40} />
				}
			</Link>
		</header>
	);
}
