import { artworkCollectionResponse } from "../../features/artworks/types";
import CollectionItem from "./CollectionItem";
import ReactPaginate from "react-paginate";
import { AppLoader } from "../Application";

interface CollectionProps {
	data: artworkCollectionResponse;
	handleClick: any;
}

export default function Collections({ data, handleClick }: CollectionProps) {
	const { loading, artworks } = data;

	return (
		<div>
			{loading === "pending" && (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						width: "100%",
						padding: "20px",
					}}
				>
					<AppLoader />
				</div>
			)}
			<section className='layout__artworks'>
				{artworks?.data.map((artwork, key) => {
					return (
						<div
							key={key}
							style={{
								minHeight: 30,
								backgroundColor: "#fafafa",
								marginBottom: 10,
							}}
						>
							<>
								<CollectionItem artwork={artwork} />
							</>
						</div>
					);
				})}
			</section>
			<ReactPaginate
				breakLabel='-'
				nextLabel='>'
				onPageChange={(e) => {
					handleClick(e.selected + 1);
					window.scrollTo(0, 0);
				}}
				pageRangeDisplayed={2}
				pageCount={5} // should be "artworks?.pagination.total_pages as number"
				initialPage={artworks?.pagination.current_page! - 1}
				previousLabel='<'
				renderOnZeroPageCount={undefined}
				activeClassName='mwaza_active'
				containerClassName='mwaza_container'
				pageClassName='mwaza_break'
			/>
		</div>
	);
}
