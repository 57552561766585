import { createBrowserRouter } from "react-router-dom";
import { AppLayout } from "../components/Application";

import ArtworksPage from "./Artworks";
import ArtworkDetailsPage from "./ArtworkDetails";

export const router = createBrowserRouter([
	{
		path: "/",
		element: (
			<AppLayout>
				<ArtworksPage />
			</AppLayout>
		),
	},
	{
		path: "/:artworkId",
		element: (
			<AppLayout>
				<ArtworkDetailsPage />
			</AppLayout>
		),
	},
]);
