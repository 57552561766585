import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import {
	artworkCollectionsSlice,
	artworkDetailsSlice,
} from "../features/artworks";

//configure store
export const store = configureStore({
	reducer: {
		fetchCollections: artworkCollectionsSlice.reducer,
		[artworkDetailsSlice.reducerPath]: artworkDetailsSlice.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(artworkDetailsSlice.middleware),
});

//export types
export type RootStore = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

//setup listeners
setupListeners(store.dispatch);
