import { createAsyncThunk } from "@reduxjs/toolkit";
import { artworkCollectionResponse } from "../../features/artworks/types";
export const API_BASE_URL =
	"https://corsproxy.io/?https://api.artic.edu/api/v1";

const fetchArtworksCollections = createAsyncThunk(
	"artworks/fetchCollections",
	async (page: number = 1) => {
		try {
			const response = await fetch(
				`${API_BASE_URL}/artworks?fields=id,title,artist_display,image_id&page=${page}`
			);
			const data = await response.json();
			return { artworks: data } as artworkCollectionResponse;
		} catch (error) {
			console.error("Error fetching artworks collections:", error);
			return null;
		}
	}
);

export { fetchArtworksCollections };
