import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { Art } from "../artworks/types";
import { API_BASE_URL } from "../../services";

interface detailsType {
	data: Art;
}

export const artworkDetailsSlice = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: API_BASE_URL,
	}),
	endpoints: (builder) => ({
		getArtwork: builder.query<detailsType, number>({
			query: (id) => `/artworks/${id}`,
		}),
	}),
});

export const { useGetArtworkQuery } = artworkDetailsSlice;
