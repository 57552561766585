import Image from "../Ui/Image";

import { Art } from "../../features/artworks/types";
import { Link } from "react-router-dom";

type Item = {
	artwork: Art;
};

export default function CollectionItem({ artwork }: Item) {
	return (
		<div className='artwork'>
			<Link to={`/${artwork.id}`}>
				<Image src={artwork.image_id as string} alt={artwork.artist_display} />
				<h2 className='artwork__title'>{artwork.title}</h2>
				<p className='artwork__artist'>{artwork.artist_display}</p>
			</Link>
		</div>
	);
}
