import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootStore } from "../store";
import { fetchArtworksCollections } from "../services";

const fetchCollections = (state: RootStore) => state.fetchCollections;

export default function useArtworkCollections() {
	const { artworks, loading } = useSelector(fetchCollections);
	const dispatch = useDispatch<AppDispatch>();

	const handleArtwork = useCallback(
		(id: number) => dispatch(fetchArtworksCollections(id)),
		[dispatch]
	);

	useEffect(() => {
		handleArtwork(artworks?.currentPage as number);
	}, [handleArtwork, artworks?.currentPage]);

	return { artworks, loading, handleArtwork };
}
