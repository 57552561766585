import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/global.scss";
import { AppProvider } from "./components/Application";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<React.StrictMode>
		<AppProvider />
	</React.StrictMode>
);
