interface imageProps {
	src: string;
	alt?: string;
	height?: number | string;
}
export default function Image({ src, alt, height = 300 }: imageProps) {
	return (
		<>
			<img
				src={`https://www.artic.edu/iiif/2/${src}/full/843,/0/default.jpg`}
				loading='lazy'
				alt={alt}
				className='ui__image'
				height={height}
			/>
		</>
	);
}
