import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { router } from "../../pages";
import { store } from "../../store";

export default function AppProvider() {
	return (
		<Provider store={store}>
			<RouterProvider router={router} />
		</Provider>
	);
}
